<div class="value">
  <!----------------           CHECKBOX column                 ---------------------->
  <ng-container *ngIf="config.tools.select">
    <mat-checkbox
      noopClick
      #checkbox="matCheckbox"
      (click)="selectClicked($event, checkbox)"
      [checked]="checked"
      [disabled]="disabled"
    ></mat-checkbox>
  </ng-container>

  <!----------------           ACTIONS buttons           ------------------->
  <ng-container *ngIf="actionBtnDisplay" [ngSwitch]="config.tools.actionButtons?.position">
    <div *ngSwitchCase="'icons'" class="action-icons">
      <mat-icon
        *ngFor="let button of actionsButtonSlot"
        (click)="emitCustomButtonEvent.emit(button)"
        [ngStyle]="{ color: button.colorIcon || null }"
        [matTooltip]="button.alias! | titlecase"
        >{{ button.icon }}</mat-icon
      >
    </div>
    <div *ngSwitchDefault>
      <mat-icon (click)="actionPanelOpened.emit()">more_vert</mat-icon>
      <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="actionsMenu"></div>
    </div>
  </ng-container>
  <!--------------------           LABELS column                 ---------------------->
  <ng-container>
    <mat-icon
      (click)="infoItemClicked.next()"
      *ngIf="labelItem && labelItem[0]"
      [ngStyle]="{ color: colorItem || 'black' }"
      matTooltip="{{ labelItem.join('&#13;') }}"
      matTooltipClass="label-tooltip"
      matTooltipPosition="right"
    >
      help
    </mat-icon>
  </ng-container>
</div>

<mat-menu #actionsMenu="matMenu" class="actionsMenu" xPosition="after">
  <ng-template
    let-customButtons="customButtons"
    let-detailsBtn="detailsBtn"
    let-duplicate="duplicate"
    let-duplicateBy="duplicateBy"
    let-editInPopup="editInPopup"
    let-isEmpty="isEmpty"
    let-item="item"
    let-removing="removing"
    matMenuContent
  >
    <div *ngIf="isEmpty" class="isEmpty" style="display: flex; justify-content: center">
      {{ 'sem_table.actions_button_empty' | translate }}
    </div>
    <button (click)="initRemove(item)" *ngIf="removing" [disabled]="disabled" mat-menu-item>
      {{ 'sem_table.remove' | translate | titlecase }}
    </button>
    <button (click)="redirectToDetails(item)" *ngIf="detailsBtn" [disabled]="disabled" mat-menu-item>
      {{ 'sem_table.details' | translate | titlecase }}
    </button>
    <button (click)="openDuplicatePopup(item)" *ngIf="duplicate" [disabled]="disabled" mat-menu-item>
      {{ 'sem_table.duplicate' | translate | titlecase }}
    </button>
    <button (click)="openEditInPopup(item)" *ngIf="editInPopup" [disabled]="disabled" mat-menu-item>
      {{ 'sem_table.edit' | translate | titlecase }}
    </button>

    <button (click)="openDuplicateBy(item, config.tools.duplicateBy!.columnBy!)" *ngIf="duplicateBy" [disabled]="disabled" mat-menu-item>
      {{ 'sem_table.duplicate_by_column' | translate: { column: config.columns[config.tools.duplicateBy!.columnBy!].alias | titlecase } }}
    </button>

    <button (click)="customButtonClicked(button, item)" *ngFor="let button of customButtons" [disabled]="disabled" mat-menu-item>
      <mat-icon *ngIf="button.icon">{{ button.icon }}</mat-icon>
      {{ button.alias | titlecase }}
    </button>
  </ng-template>
</mat-menu>
