import { Pipe, PipeTransform } from '@angular/core';
import { FilterGroupModel } from '../../models/filter.model';

@Pipe({
  name: 'filterGroupDisplay',
})
export class FilterGroupDisplayPipe implements PipeTransform {
  truncate = 10;

  transform(filterGroups: FilterGroupModel[]): string {
    let res = '';

    filterGroups.forEach((group) => {
      group.filters.forEach((filter) => {
        const value = filter.value && filter.value.length > this.truncate ? `${filter.value.substring(0, this.truncate)}...` : filter.value;
        res += `${filter.param} ${filter.symbol} ${value}`;
      });
    });

    return res;
  }
}
