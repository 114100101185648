/* eslint-disable @typescript-eslint/dot-notation */
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { orderBy } from 'lodash';
import { Observable, catchError, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import ApiUrls from 'src/app/configs/api-urls.config';
import { ERROR_NOTIFICATION_SKIP } from 'src/app/interceptors/error-interceptor.service';
import { AdditionalFiltersInterface } from 'src/app/shared/model/data-table.model';
import { PaginationInterface, ResponseV2Interface } from 'src/app/shared/model/response.model';
import { UserModel } from 'src/app/shared/model/user.model';
import { SingleChangeInterface } from 'src/app/shared/sem-table/models/change.model';
import { TableChangeDataEmittedInterface } from 'src/app/shared/sem-table/models/TableChangeDataEmitted.model';
import { FilterGroupModel } from 'src/app/shared/sem-table/public-api';
import { ConnectionUrlInterface } from '../project/connections/connections.model';
import { ProjectService } from '../project/project.service';
import { TasksFormService } from './tasks-form.service';
import { TaskStatusEnum } from './tasks.enum';
import { CommentAttachment, NewEmailCommentInterface, NewTaskCommentInterface, TaskCommentInterface, TaskInterface } from './tasks.model';
import { CursorModel } from '../../shared/model/cursor.model';

// @TODO: dodac typy do zwrotek!!
@Injectable({
  providedIn: 'root',
})
export class TasksService extends TasksFormService {
  private readonly http = inject(HttpClient);
  private readonly projectService = inject(ProjectService);

  createDraftTask(taskData: Partial<TaskInterface> = {}): Observable<TaskInterface> {
    const defaultData: Partial<TaskInterface> = {
      status: TaskStatusEnum.new,
    };

    if (!taskData.parent_id) {
      defaultData.project_id = this.projectService.activeProject$.getValue()?.id || null;
    }

    const task: Partial<TaskInterface> = {
      ...defaultData,
      ...taskData,
    };

    const formData: FormData = this.createTaskFormData(task);
    return this.http.post<TaskInterface>(ApiUrls.tasks, formData);
  }

  createTask(task: Partial<TaskInterface>): Observable<TaskInterface> {
    !task.status && (task.status = TaskStatusEnum.new);
    const formData: FormData = this.createTaskFormData(task);
    return this.http.post<TaskInterface>(ApiUrls.tasks, formData);
  }

  createTaskComment(taskId: number, data: NewTaskCommentInterface): Observable<TaskCommentInterface> {
    const formData: FormData = this.createTaskCommentFormData(data);
    return this.http.post<TaskCommentInterface>(ApiUrls.tasksByIdComments.prepareUrl({ taskId }), formData);
  }

  editTaskComment(taskId: number, commentId: number, data: NewTaskCommentInterface): Observable<TaskCommentInterface> {
    const formData: FormData = this.createTaskCommentFormData(data);
    return this.http.post<TaskCommentInterface>(
      ApiUrls.tasksByIdCommentsById.prepareUrl({
        taskId,
        commentId,
      }),
      formData,
    );
  }

  editTask(taskId: number, task: Partial<TaskInterface>): Observable<TaskInterface> {
    const formData: FormData = this.createTaskFormData(task);
    formData.append('_method', 'PATCH'); // @TODO: wynika to z problemu po stronie PHP, gdzie form-data nie może lecieć put'em oraz patch'em
    return this.http.post<TaskInterface>(ApiUrls.tasksById.prepareUrl({ taskId }), formData);
  }

  editTaskInline(singleChange: SingleChangeInterface): Observable<TaskInterface | null> {
    const { changedData, revertSingleChange, task } = this.catchSingleTaskChange(singleChange);

    return this.editTask(task.id!, changedData).pipe(
      catchError(() => {
        revertSingleChange();
        return of(null);
      }),
    );
  }

  editTasks(tasks: TaskInterface[]): Observable<TaskInterface[]> {
    return forkJoin(tasks.map((task) => this.editTask(task.id!, task)));
  }

  deleteTask(taskId: number): Observable<void> {
    return this.http.delete<void>(ApiUrls.tasksById.prepareUrl({ taskId }));
  }

  deleteComment(taskId: number, commentId: number): Observable<void> {
    return this.http.delete<void>(ApiUrls.tasksByIdCommentsById.prepareUrl({ taskId, commentId }));
  }

  deleteAttachment(attachmentPath: string): Observable<any> {
    return this.http.delete(attachmentPath);
  }

  getContributors(search: string | null = null, projectId: number | null = null, name: string | null = null) {
    let params = new HttpParams();

    search && (params = params.set('search', search));
    name && (params = params.set('name', name));
    projectId && (params = params.set('project_id', projectId));

    return this.http.get<ResponseV2Interface<UserModel[]>>(ApiUrls.tasksContributors, { params }).pipe(map((res) => res.data));
  }

  getKanbanTasks(
    filterGroups: FilterGroupModel[] = [],
    additionalFilters: AdditionalFiltersInterface | null = null,
  ): Observable<PaginationInterface<TaskInterface>> {
    // @TODO: obsłużyć paginację - do ustalenia jak...
    const params = {
      // per_page: itemsPerPage,
      // page: currentPage,
      // order: tableParams.sorting,
      // direction: tableParams.sorting,
      filterGroups,
      // project_id: null,
    };

    additionalFilters && ((params['additional_filters' as keyof typeof params] as any) = additionalFilters);

    return this.http.post<PaginationInterface<TaskInterface>>(ApiUrls.tasksKanban, params).pipe(
      map((res) => {
        res.data = orderBy(res.data, ['order'], ['desc']);
        return res;
      }),
    );
  }

  getTask(taskId: number): Observable<TaskInterface> {
    return this.http.get<TaskInterface>(ApiUrls.tasksById.prepareUrl({ taskId })).pipe(map((task) => this.prepareTask(task)));
  }

  getTaskComments(taskId: number, params: Partial<CursorModel>): Observable<TaskCommentInterface[]> {
    return this.http
      .get<ResponseV2Interface<TaskCommentInterface[]>>(ApiUrls.tasksByIdComments.prepareUrl({ taskId }), { params })
      .pipe(map((res) => res.data));
  }

  getTasks(
    tableParams: TableChangeDataEmittedInterface | null,
    additionalFilters: AdditionalFiltersInterface | null = null,
  ): Observable<PaginationInterface<TaskInterface>> {
    const { currentPage, filterGroups, itemsPerPage, sorting } = tableParams || {};

    const params = {
      direction: sorting?.direction!,
      filterGroups: filterGroups || [],
      order: sorting?.columnName!,
      page: currentPage,
      per_page: itemsPerPage,
    };

    additionalFilters && ((params['additional_filters' as keyof typeof params] as any) = additionalFilters);

    return this.http.post<PaginationInterface<TaskInterface>>(ApiUrls.tasksSearch, params).pipe(
      map((res) => {
        res.data.map((task) => this.prepareTask(task));

        return res;
      }),
    );
  }

  // @TODO: Czy to jest zbędne i każda zmaina statusu powinna iść akcjami?
  // setStatus(taskId: number, status: TaskStatusEnum): Observable<TaskInterface> {
  //   return this.http.patch<TaskInterface>(ApiUrls.tasksSetStatus.prepareUrl({ taskId }), { status });
  // }

  getGmailConnections(): Observable<ConnectionUrlInterface> {
    let params: HttpParams = new HttpParams();
    params = params.set('redirect', 'CONNECTIONS');
    params = params.set('type', 'GOOGLE');
    params = params.set('services', '10');

    return this.http
      .get<{ data: { item: ConnectionUrlInterface } }>(ApiUrls.connections + '/url', { params })
      .pipe(map((response: { data: { item: ConnectionUrlInterface } }) => response.data.item));
  }

  downloadAttachment(attachment: CommentAttachment): Observable<Blob> {
    const url: string = attachment.path;
    const context = new HttpContext().set(ERROR_NOTIFICATION_SKIP, true);
    return this.http.get(url, { context, responseType: 'blob' });
  }

  private createTaskCommentFormData(data: NewTaskCommentInterface | NewEmailCommentInterface): FormData {
    const formData: FormData = new FormData();
    formData.append('comment', data.comment);
    if (data.attachments && data.attachments.length) {
      data.attachments.forEach((attachment: File) => {
        formData.append('attachments[]', attachment);
      });
    }

    if ('external_mail' in data) {
      formData.append('external_mail', String(data.external_mail));
      formData.append('mail_from', data.mail_from);
      formData.append('mail_to', data.mail_to);
      formData.append('subject', data.subject);
    }

    return formData;
  }

  private prepareTask(task: TaskInterface) {
    task._project_name = task.project?.name;
    task._type = task.task_trigger_id ? 'system' : 'manual';

    return task;
  }
}
