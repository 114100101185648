@if (
  filters && filters.filterGroups && ((filters.filterGroups[0] && filters.filterGroups[0].filters!.length) || filters.filterManualChanges)
) {
  <div id="filterListContainer">
    <div class="filter-display active">
      <div
        *ngFor="let group of filters.filterGroups; let i = index"
        [ngStyle]="{
          color: color,
          cursor: cursorDefault ? 'default' : 'pointer',
          'font-size': fontSize + 'px',
        }"
        class="group"
      >
        <ng-container
          *ngTemplateOutlet="groupSingleDisplayer; context: { group: group, isInBrackets: filters.filterGroups[i + 1] || i > 0 }"
        ></ng-container>
      </div>
      @if (filters.filterManualChanges) {
        {{ 'sem_table.and' | translate }} {{ 'sem_table.has_manual_changes' | translate }}
      }
    </div>
  </div>
} @else {
  <ng-template #empty>
    <div class="filter-display">
      <span id="empty-container">{{ 'sem_table.no_filter_applied' | translate }}</span>
    </div>
  </ng-template>
}

<ng-template #groupSingleDisplayer let-group="group" let-isInBrackets="isInBrackets">
  <span *ngIf="group.operator !== operators.none && group.operator" class="group-operator">
    {{ 'sem_table.' + group.operator | translate }}
  </span>

  <span *ngIf="isInBrackets" class="bracket">(</span>

  <span *ngFor="let filter of group.filters" class="filter">
    <ng-container *ngTemplateOutlet="singleFilterDisplayer; context: { filter: filter }"></ng-container>
  </span>

  <span *ngIf="isInBrackets" class="bracket">)</span>
</ng-template>

<ng-template #singleFilterDisplayer let-filter="filter">
  <span *ngIf="filter.operator !== operators.none && filter.operator" class="operator">
    {{ 'sem_table.' + filter.operator | translate }}</span
  >

  <span class="param">{{ config.columns[filter.param].alias }} </span>
  <span class="symbol"> {{ filter.symbol | filterTypeAlias }} </span>

  <ng-container *ngTemplateOutlet="valueDisplayer; context: { column: config.columns[filter.param], filter: filter }"></ng-container>

  <span *ngIf="filter.additional_sources?.translate_key">
    {{ 'sem_table.and' | translate }}
    {{ 'sem_table.additional_filters.' + filter.additional_sources.translate_key | translate: filter.additional_sources }}
  </span>
</ng-template>

<ng-template #valueDisplayer let-column="column" let-filter="filter">
  <span
    *ngIf="
      filter.symbol !== filterTypes.is_empty &&
      filter.symbol !== filterTypes.is_empty &&
      filter.symbol !== filterTypes.not_empty &&
      filter.symbol !== filterTypes.is_uppercase &&
      filter.symbol !== filterTypes.has_manual_changes
    "
    [ngSwitch]="column.valueType"
    class="value"
  >
    <ng-container *ngSwitchCase="columnValueTypes.date">
      {{ filter.value[0] | date: 'HH:mm dd/MM/yyyy' }} -
      {{ filter.value[1] | date: 'HH:mm dd/MM/yyyy' }}
    </ng-container>

    <ng-container *ngSwitchCase="columnValueTypes.boolean">
      {{ filter.value ? 'true' : 'false' }}
    </ng-container>

    <ng-container *ngSwitchCase="columnValueTypes.array">
      {{ filter.value | userIdsToEmails | async }}
    </ng-container>

    <ng-container *ngSwitchDefault>
      defult
      {{ filter.value | mapColumnValue: column }}
    </ng-container>
  </span>
</ng-template>
