<ng-container *ngIf="column && action" [ngSwitch]="column.type">
  <!-------------------------------            SIMPLE COLUMN               --------------------------------------------------->
  <div *ngSwitchCase="columnTypes.SIMPLE" @appearAnimation class="value-action-container">
    <ng-container *ngIf="action" [ngSwitch]="action.type">
      <sem-value-generator (valueChanged)="valueGeneratorChanged($event)" *ngSwitchCase="actionTypes.generateValue" [config]="config">
      </sem-value-generator>

      <sem-link-builder (valueChanged)="valueLinkChanged($event)" *ngSwitchCase="actionTypes.modifyLink" [action]="$any(action)">
      </sem-link-builder>

      <div *ngSwitchCase="actionTypes.fixUppercase"></div>

      <sem-add-found-from-field
        (valueChanged)="valueAddFoundFromFieldChanged($event)"
        *ngSwitchCase="actionTypes.addFoundFromField"
        [config]="config"
        [targetColumn]="config.columns[action.columnName]"
      >
      </sem-add-found-from-field>

      <mat-form-field *ngSwitchCase="actionTypes.addEnd" appearance="outline">
        <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
        <input [(ngModel)]="action.value" matInput name="value" placeholder="Value" required/>
      </mat-form-field>

      <mat-form-field *ngSwitchCase="actionTypes.addStart" appearance="outline">
        <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
        <input [(ngModel)]="action.value" matInput name="value" placeholder="Value" required/>
      </mat-form-field>

      <mat-form-field *ngSwitchCase="actionTypes.override" appearance="outline">
        <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
        <input
          [(ngModel)]="action.value"
          [type]="config.columns[action.columnName].valueType === columnValueTypes.number ? 'number' : 'text'"
          matInput
          trimSpaces
          name="value"
          placeholder="Value"
          required
        />
      </mat-form-field>

      <mat-form-field
        *ngSwitchCase="[actionTypes.add, actionTypes.divide, actionTypes.multiply, actionTypes.subtract] | switchMultiCase: action.type"
        appearance="outline"
      >
        <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
        <input [(ngModel)]="action.value" matInput trimSpaces name="value" placeholder="Value" required type="number" />
      </mat-form-field>

      <div *ngSwitchCase="actionTypes.setEmpty"></div>

      <sem-replace *ngSwitchCase="actionTypes.replace" [action]="$any(action)"></sem-replace>

      <sem-regexp-replace *ngSwitchCase="actionTypes.regexpReplace" [action]="$any(action)"></sem-regexp-replace>
    </ng-container>
  </div>

  <!-------------------------------            SELECT COLUMN               --------------------------------------------------->
  <div *ngSwitchCase="columnTypes.SELECT" @appearAnimation class="value-action-container">
    <ng-container *ngIf="action" [ngSwitch]="action.type">
      <mat-form-field *ngSwitchCase="actionTypes.override" appearance="outline" ngDefaultControl>
        <mat-select (valueChanged)="setValueInAction($event)" [(ngModel)]="action.value">
          <ng-container *ngFor="let option of $any(column).possibleValues">
            <mat-option *ngIf="option" [value]="option">{{ option | mapColumnValue: column }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <div *ngSwitchCase="actionTypes.setEmpty"></div>
    </ng-container>
  </div>

  <!-------------------------------            AUTOCOMPLETE COLUMN               --------------------------------------------------->
  <div *ngSwitchCase="columnTypes.AUTOCOMPLETE" @appearAnimation class="value-action-container">
    <ng-container *ngIf="action" [ngSwitch]="action.type">
      <!-- TODO: utworzyć input [autocompleteColumn]="column" -->
      <!--      [autocompleteColumn]="column"-->
      <sem-value-generator
        (valueChanged)="valueGeneratorChanged($event)"
        *ngSwitchCase="actionTypes.generateValue"
        [autocompleteService]="autocompleteService"
        [config]="config"
      >
      </sem-value-generator>

      <mat-form-field *ngSwitchCase="actionTypes.addEnd" appearance="outline">
        <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
        <input [(ngModel)]="action.value" matInput trimSpaces name="value" placeholder="Value" required />
      </mat-form-field>

      <mat-form-field *ngSwitchCase="actionTypes.addStart" appearance="outline">
        <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
        <input [(ngModel)]="action.value" matInput trimSpaces name="value" placeholder="Value" required />
      </mat-form-field>

      <sem-autocomplete-column
        (autocompleteChanged)="autocompleteChanged($event)"
        (valueChanged)="setValueInAction($event)"
        *ngSwitchCase="actionTypes.override"
        [column]="$any(column)"
        [displayEditable]="true"
        [formFieldAppearance]="'outline'"
        [placeholderForce]="'value'"
        [value]="action.value"
        class="columnComponent"
      >
      </sem-autocomplete-column>

      <mat-form-field
        *ngSwitchCase="[actionTypes.add, actionTypes.divide, actionTypes.multiply, actionTypes.subtract] | switchMultiCase: action.type"
        appearance="outline"
      >
        <mat-label>{{ 'sem_table.value' | translate }}</mat-label>
        <input [(ngModel)]="action.value" [appOnlyNumber]="true" matInput name="value" placeholder="Value" required />
      </mat-form-field>

      <sem-replace-autocomplete
        (autocompleteChanged)="autocompleteChanged($event)"
        *ngSwitchCase="actionTypes.replace"
        [action]="$any(action)"
        [column]="column"
      ></sem-replace-autocomplete>
    </ng-container>
  </div>

  <!-------------------------------            DATE COLUMN               --------------------------------------------------->
  <div *ngSwitchCase="columnTypes.DATE" @appearAnimation class="value-action-container date">
    <sem-date-column
      (valueChanged)="setValueInAction($event)"
      *ngIf="action.type === actionTypes.override"
      [column]="$any(column)"
      [displayOnlyEditable]="true"
      [value]="null!"
      class="columnComponent"
    >
    </sem-date-column>
  </div>

  <!-------------------------------            CUSTOM COLUMN               --------------------------------------------------->
  <div *ngSwitchCase="columnTypes.CUSTOM" @appearAnimation class="value-action-container custom-column">
    <div *ngIf="action.type === actionTypes.override" class="info">{{ 'sem_table.value' | translate }}:</div>

    <!-- TODO: utworzyć input i podpiąć popup [popupService]="popupService" -->
    <sem-custom-column
      (valueChanged)="setValueInAction($event)"
      *ngIf="action.type === actionTypes.override"
      [autocompleteService]="autocompleteService"
      [column]="$any(column)"
      [popupService]="popupService"
      [value]="action.value"
      class="columnComponent"
    >
    </sem-custom-column>
  </div>

  <!-------------------------------            BOOLEAN COLUMN               --------------------------------------------------->
  <div *ngSwitchCase="columnTypes.BOOLEAN" @appearAnimation class="value-action-container boolean">
    <div (click)="slider.toggle()" class="info">{{ 'sem_table.value' | translate }}:</div>

    <mat-slide-toggle #input #slider [(ngModel)]="action.value" color="primary" name="value"></mat-slide-toggle>
  </div>
</ng-container>
